import React from 'react';
import ns1Logo from '../../static/images/sponsors/ns1.svg';
import dewawebLogo from '../../static/images/sponsors/dewaweb.png';

function SponsorPlatinum2() {
  return (
    <div className="flex content-center items-center max-w-3xl mx-auto">
      <div className="w-full md:w-1/2 lg:w-1/2 p-2">
        <a
          href="https://ns1.com"
          className="sponsors-logo"
          rel="noopener noreferrer"
          target="_blank"
          data-umami-event="sponsor-ns1"
        >
          <img className="mx-auto md:mx-0" src={ns1Logo} alt="NS1" />
        </a>
      </div>

      <div className="w-full md:w-1/2 lg:w-1/2 p-2">
        <a
          href="https://www.dewaweb.com"
          className="sponsors-logo"
          rel="noopener noreferrer"
          target="_blank"
          data-umami-event="sponsor-dewaweb"
        >
          <img
            className="dewaweb max-w-xxs mx-auto"
            src={dewawebLogo}
            alt="Dewaweb"
          />
        </a>
      </div>
    </div>
  );
}
export default SponsorPlatinum2;
